<template>
  <v-app>
    <v-main>
      <v-img
        :class="{
          'v-image--sm': this.$vuetify.breakpoint.smAndDown,
          'v-image--md': this.$vuetify.breakpoint.mdAndDown
        }"
        src="@/assets/MMS-Login-Page.jpg"
        min-height="100%"
      >
        <!-- Gradient from background image: gradient="to right, #3F5FD0DF, #3F0047BA, #3FA25EB5" -->
        <v-container
          id="router-container"
          class="error-page align-start"
          tag="section"
          fill-height
        >
          <v-row class="text-center justify-center white--text mt-12">
            <v-col cols="auto">
              <h1 class="text-h6 font-weight-black">
                You are lost.
              </h1>

              <div class="text-h2 my-10">
                This page doesn't exist.
              </div>

              <v-btn
                :to="{ name: 'CampaignDashboard' }"
                color="primary"
              >
                Take me home.
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss">
.error-page h1 {
  font-size: 86px !important;
  letter-spacing: 10px !important;
  font-weight: 700 !important;
  margin-top: 60px;
  margin-bottom: 100px;
}
.error-page h1 {
  font-size: 12rem;
}
</style>
