var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c(
            "v-img",
            {
              class: {
                "v-image--sm": this.$vuetify.breakpoint.smAndDown,
                "v-image--md": this.$vuetify.breakpoint.mdAndDown
              },
              attrs: {
                src: require("@/assets/MMS-Login-Page.jpg"),
                "min-height": "100%"
              }
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "error-page align-start",
                  attrs: {
                    id: "router-container",
                    tag: "section",
                    "fill-height": ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "text-center justify-center white--text mt-12"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "h1",
                            { staticClass: "text-h6 font-weight-black" },
                            [
                              _vm._v(
                                "\n              You are lost.\n            "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "text-h2 my-10" }, [
                            _vm._v(
                              "\n              This page doesn't exist.\n            "
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: { name: "CampaignDashboard" },
                                color: "primary"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Take me home.\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }